import styled, { css } from 'styled-components';
import ArrowLink from 'Elements/Link/Arrow';

export const Container = styled.article`
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding: 0.5rem;
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        height: 12rem;
        margin-bottom: 0.5rem;
        overflow: hidden;
        border-radius: 0.5rem;

        @media ${theme.breakpoints.large.media} {
            height: 12.5rem;
            margin-bottom: 1rem;
        }
    `}
`;

export const Inner = styled.div`
    > * {
        margin: 0 0 0.5rem 0;
    }
`;

export const Heading = styled.h3`
    line-height: 1.25;
    font-size: 1.5rem;
`;

export const Link = styled(ArrowLink)`
    ${({ theme }) => css`
        @media ${theme.breakpoints.large.media} {
            font-size: 1.25rem;
        }
    `}
`;
