import React from 'react';
import colors from 'Theme/lightTheme/colors';

import BasicKeyStat, { BasicKeyStatProps } from 'Components/KeyStat/Basic';

import Grid, { Col } from 'Components/Grid';

import { Wrapper, Container, Heading, Text, Link } from './Flexible.styled';
import { LinkProps } from 'Elements/Link';

interface FlexibleProps extends BasicKeyStatProps {
    id: string;
}
export interface Props {
    sectionID: string;
    keyStats: FlexibleProps[];
    link?: LinkProps;
    heading: string | React.ReactNode;
    headingType?: 'h2' | 'h3';
    text: React.ReactNode;
    headingColor?: string;
    backgroundColor?: string;
    textColor?: string;
}

const Flexible: React.FC<Props> = props => {
    const {
        sectionID,
        heading,
        keyStats,
        text,
        backgroundColor,
        textColor,
        headingColor,
        link,
        headingType = 'h2'
    } = props;

    return (
        <Wrapper
            id={sectionID}
            style={
                {
                    '--flexible-bg-color': backgroundColor,
                    '--flexible-stat-heading-color': headingColor,
                    '--flexible-stat-txt-color': textColor
                } as React.CSSProperties
            }
        >
            <Grid>
                <Col m={10} h={6}>
                    <Heading as={headingType}>{heading}</Heading>
                    <Text>{text}</Text>
                    {link && <Link {...link} />}
                </Col>
                <Col m={10} h={6}>
                    <Container>
                        {keyStats.map(({ id, ...props }) => (
                            <li key={id}>
                                <BasicKeyStat
                                    {...props}
                                    statColor={
                                        props.textColor ? props.textColor : colors.accents[5].hex
                                    }
                                    textColor={
                                        props.textColor ? props.textColor : colors.accents[5].hex
                                    }
                                />
                            </li>
                        ))}
                    </Container>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default Flexible;
