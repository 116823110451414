import React from 'react';
import Image from 'next/image';

import { LinkProps } from 'Elements/Link';
import ArrowLink from 'Elements/Link/Arrow';

import Grid, { Col } from 'Components/Grid';

import Wave103 from 'Public/graphics/waves/text-block/text-block-103.svg';

import { Container, Background, CopyContainer, Filter } from './Wave.styled';

export interface Props {
    children: React.ReactNode;
    heading?: string;
    link?: LinkProps;
    wave?: boolean;
}

const Wave: React.FC<Props> = ({ children, heading, link, wave = true }) => {
    return (
        <Container>
            {wave && (
                <>
                    <Filter />
                    <Background className="background">
                        <Image
                            src={Wave103}
                            alt=""
                            width={922}
                            height={300}
                            style={{ objectFit: 'cover' }}
                        />
                    </Background>
                </>
            )}
            <Grid>
                <Col m={10} l={8}>
                    <CopyContainer>
                        {heading && <h2>{heading}</h2>}
                        {children}
                        {link && <ArrowLink {...link} />}
                    </CopyContainer>
                </Col>
            </Grid>
        </Container>
    );
};

export default Wave;
