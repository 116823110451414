import React from 'react';
import type CSS from 'csstype';

import Grid, { Col, type ColumnSpan } from 'Components/Grid';
import ImageCaption, { ImageCaptionProps } from 'Components/ImageCaption';

import { StyledCol, DescriptionWrapper, ImageWrapper } from './ImageAndText.styled';

export interface Props {
    description: React.ReactNode;
    imageCaption: ImageCaptionProps;
    imageLeftOfDescription: boolean;
    imageColumnWidth: number;
    textVerticalAlignment?: CSS.Property.AlignSelf;
    offsetImage?: boolean;
}

const ImageAndText: React.FC<Props> = props => {
    const {
        description,
        imageCaption,
        imageLeftOfDescription,
        imageColumnWidth,
        textVerticalAlignment,
        offsetImage = false
    } = props;

    let textColSpan: ColumnSpan = 12 - imageColumnWidth;
    let imageColSpan: ColumnSpan = imageColumnWidth;

    if (offsetImage) {
        if (!imageLeftOfDescription) {
            textColSpan = 13 - imageColumnWidth - 2;
            imageColSpan = [13 - imageColumnWidth, 13];
        } else {
            imageColSpan = imageColumnWidth;
            textColSpan = [imageColumnWidth + 2, 13];
        }
    }

    return (
        <Grid rowGap="1rem">
            <StyledCol
                m={10}
                l={textColSpan}
                align={textVerticalAlignment}
                $order={imageLeftOfDescription ? 1 : 0}
            >
                <DescriptionWrapper>{description}</DescriptionWrapper>
            </StyledCol>
            <Col l={imageColSpan}>
                <ImageWrapper>
                    <ImageCaption {...imageCaption} />
                </ImageWrapper>
            </Col>
        </Grid>
    );
};

export default ImageAndText;
