import React from 'react';
import NextImage, { StaticImageData } from 'next/image';

import { Container, ImageWrapper, Inner, Heading, Link } from './Borderless.styled';
import { ArrowLinkProps } from 'Elements/Link/Arrow';

export interface Props {
    image: {
        src: StaticImageData | string;
        alt?: string;
    };
    heading?: string;
    text: string | React.ReactNode;
    link?: ArrowLinkProps;
}

const Borderless: React.FC<Props> = props => {
    const { image, heading, link, text } = props;

    return (
        <Container>
            <ImageWrapper>
                <NextImage
                    src={image.src}
                    alt={image.alt || ''}
                    fill
                    style={{ objectFit: 'cover' }}
                />
            </ImageWrapper>
            <Inner>
                {heading && <Heading>{heading}</Heading>}
                {typeof text === 'string' ? <p>{text}</p> : text}
            </Inner>
            {link && <Link {...link} iconOutline iconBorder={false} />}
        </Container>
    );
};

export default Borderless;
