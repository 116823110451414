import React from 'react';
import Script from 'next/script';

import Section from 'Elements/Section';
import { H2 } from 'Elements/Typography';

import Grid, { Col } from 'Components/Grid';

import { Iframe } from './Unibuddy.styled';

export interface Props {
    id: string;
    iframe: {
        src: string;
    };
    heading: string;
    headingType?: 'h2' | 'h3';
    columnStart?: number;
    columnEnd?: number;
}

const Unibuddy: React.FC<Props> = ({
    iframe,
    heading,
    headingType = 'h2',
    id,
    columnStart,
    columnEnd
}) => {
    return (
        <Section id={id} background>
            <Grid>
                <Col l={columnStart && columnEnd && [columnStart, columnEnd]}>
                    <H2 as={headingType}>{heading}</H2>
                    <Iframe
                        data-ub-cookie-consent="necessary"
                        id="unibuddy-iframe"
                        src={iframe.src}
                        title="Unibuddy"
                        width="100%"
                    />
                    <Script
                        src={`https://cdn.unibuddy.co/unibuddy-iframe.js`}
                        type="text/javascript"
                    />
                </Col>
            </Grid>
        </Section>
    );
};

export default Unibuddy;
